import React, { useState, useEffect,response } from "react";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import "./css/registerUserInfo.css";
import common from "../utils/common"
import { useNavigate } from "react-router-dom";
import config from "../utils/config";
import "./css/editprofile.css";
import { addSlash, removeHostUrl } from "../utils/helper";

const customStyles = {
    control: base => ({
        ...base,
        height: 50,
        minHeight: 50,

    })
};

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function Editprofile() {

    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [getUserProfile, setGetUserProfile] = useState({
        name: "",
        phone: "",
        gender: "",
        age: "",
        profileImg: ""
    });

    const [userData, setUserData] = useState({
        name: "",
        phone: "",
        gender: "",
        age: "",
        // userType:""

    });
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");


    const [fileList, setFileList] = useState([]);



    const isDesktop = useMediaQuery({ minWidth: 768 });
    const isMobile = useMediaQuery({ maxWidth: 767 });


    useEffect(() => {
        setUserData({ ...userData, phonecode: selectedCountry?.value || '+91' });
        // console.log("userDataPhoneCode", userData.phonecode)
    }, [selectedCountry]);


    const OnSubmitregisteruserHandle = async (event) => {
        event.preventDefault();
        // 

        const name = event.target.name.value;
        const phone = event.target.phone.value;
        const gender = event.target.gender.value;
        const age = event.target.age.value;
        // const userType = event.target.userType.value;

        const registerInfoData = {
            ...userData,
            name,
            phone,
            gender,
            age,
            // userType,
        };

        const phonecode = registerInfoData?.phonecode.split('-');

        registerInfoData["phone"] = `+${phonecode[0] || ""}-${registerInfoData.phone || ""}-${phonecode[1] || ""}`;


        setUserData(registerInfoData);
        // 
        if (registerInfoData) {
            const headers = { ...config.headers };
            headers['Authorization'] = localStorage.getItem('Authorization');
            const response = await fetchFunction.put(apiMapper.EDIT_PROFILE, registerInfoData, config.host, headers);
            // 
            if ([200, 201].includes(response?.status)) {
                alert("successfully updated profile")

                localStorage.setItem("name", registerInfoData?.name);
                localStorage.setItem("profileImg", registerInfoData?.profileImg);
                localStorage.setItem("gender", registerInfoData?.gender);
                // localStorage.setItem("userType", registerInfoData?.userType);

                navigate('/');
                navigate(0);
            }
        }

    };

    useEffect(() => {
        if (getUserProfile.profileImg) {
            const imageData = {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: `${getUserProfile.profileImg}`,
            }
            setFileList([imageData]);
        }

    }, [getUserProfile])


    const getProfile = async () => {

        const headers = { ...config.headers };
        headers['Authorization'] = localStorage.getItem('Authorization');
        const getDetailsResponse = await fetchFunction.get(apiMapper.GET_PROFILE, config.host, headers);

        if ([200, 201].includes(getDetailsResponse?.status)) {
            // console.log("getDetailsResponse", getDetailsResponse);
            const phonenoWithCode = getDetailsResponse?.data?.data?.phone?.split('-');
            const phonecode = phonenoWithCode[0] + '-' + phonenoWithCode[2];
            const phone = phonenoWithCode[1];


            setGetUserProfile({ ...getDetailsResponse?.data?.data, phone, phonecode });
            // console.log({ "phoneCode": phonecode, phone: phone })
            fetchCountryCode(phonecode.replace('+', ''));
            //    console.log("getProfileData", getUserProfile)

        } else if ([401].includes(getDetailsResponse?.status)) {
            localStorage.clear();
            localStorage.clear();
            navigate('/')
        }
    }

    // country code api 
    async function fetchCountryCode(phonecodeParma) {
        //console.log('hjhjhjhjhjhjhjhjhjhj');
        const result = await fetchFunction.get(apiMapper.GET_COUNTRYCODE);

        if ([200, 201].includes(result?.status)) {
            const countriesNameWithFlag = [];
            const defaultCountry = [];

            result.data.data.message.map((e) => {
                const countryCode = '+' + e.phonecode.replace('+', '').replace('-', '') + " " + e.name + " " + e.flag;
                const data = { value: e.phonecode + '-' + e.isoCode, label: countryCode };
                const phonecode = { value: e.phonecode + '-' + e.isoCode, label: countryCode };
                // 
                countriesNameWithFlag.push(data);
                if (data.value === phonecodeParma) {
                    defaultCountry.push(phonecode);
                }
            });
            // console.log('defaultCountry', defaultCountry)

            setCountries(countriesNameWithFlag);
            setSelectedCountry(defaultCountry?.[0]);

        } else {
            return <h2>Somthing Went Wrong please retry</h2>;
        }
    }
    useEffect(() => {
        const isValid = common.isLoggedIn();
        if (!isValid) {
            localStorage.clear();
            localStorage.clear();
            navigate('/')
            navigate(0);
            return;
        }
        getProfile();

    }, []);


    const userNameChange = (e) => {

        setGetUserProfile({ ...getUserProfile, name: e.target.value })
    }

    const userAgeChange = (e) => {

        setGetUserProfile({ ...getUserProfile, age: e.target.value })
    }

    const userPhoneChange = (e) => {
        setGetUserProfile({ ...getUserProfile, phone: e.target.value })
    }





    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
        );
    };
    // const handleChange = (event) => {
    //     
    //     // setFileList(newFileList)

    // };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    // const handleFileUpload = async (event) => {
    //     
    //     const file = event.file?.originFileObj;
    //     
    //     const formData = new FormData();
    //     console.log('')
    //     formData.append("file", file);
    //     // make a POST request
    //     const uploadResponse = await fetchFunction.upLoadFile(
    //         apiMapper.UPLOAD_FILE,
    //         formData
    //     );
    //     if ([200, 201].includes(uploadResponse?.status)) {
    //         const image = uploadResponse.data.files[0];
    //         

    //         const imagePriviewData = {
    //             uid: image.split('/').reverse()[0].split('.')[0],
    //             name: image.split('/').reverse()[0],
    //             status: 'done',
    //             url: image,
    //         };
    //         
    //         // setFileList([...fileList, imagePriviewData]);
    //         setUserData({ ...userData, profileImg: image });
    //     }

    // };


    const handleFileUpload = async (event) => {
        // 
        const file = event.file?.originFileObj;

        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            // make a POST request
            const uploadResponse = await fetchFunction.upLoadFile(
                apiMapper.UPLOAD_FILE,
                formData
            );
            if ([200, 201].includes(uploadResponse?.status)) {
                const image = uploadResponse.data.files[0];
                // 

                const imagePriviewData = {
                    uid: image.split('/').reverse()[0].split('.')[0],
                    name: image.split('/').reverse()[0],
                    status: 'done',
                    url: removeHostUrl(image),
                };
                // 
                setFileList([...fileList, imagePriviewData]);
                setUserData({ ...userData, profileImg: image });
            }
        } else if (event?.file?.status === 'removed') {
            const imageUrl = removeHostUrl(event?.file?.url, true);
            const deletePayload = { "files": [imageUrl] }
            const deleteImageResp = await fetchFunction.delete(apiMapper.UPLOAD_FILE, deletePayload);
            if (deleteImageResp?.status === 200) {
                setFileList(fileList.filter((e) => {
                    if (e.url !== addSlash(imageUrl)) return true;
                }))
            }

        }


    };

    useEffect(() => {
        // console.log('fileList', fileList)
    }, [fileList])





    return (
        <>
            <div className="container-fluid editprofile">
                <div className="container  mb-5 d-flex justify-content-center align-items-center">
                    <div className="position-relative profilejustification " style={{ width: (isDesktop ? "40%" : "100%") }}>
                        <div className="row text-center py-3 mt-md-2 mb-md-2 mt-5  heading">
                            <h3>Edit Profile</h3>
                        </div>
                        <form className="" onSubmit={OnSubmitregisteruserHandle}>
                            <div className=" group-inputs mt-3">
                                
                                <input type="text" className="form-control" id="name" value={getUserProfile?.name} onChange={(e) => userNameChange(e)} required />
                            </div>
                            <div className="row mt-3">
                                {/* <div className="col-4"> </div> */}
                                <div className="col-6">
                                    <Select
                                        options={countries}
                                        value={selectedCountry}
                                        id="countrycode"
                                        styles={customStyles}
                                        // defaultValue={selectedCountry}
                                        onChange={(selectedOption) =>
                                            setSelectedCountry(selectedOption)
                                        }
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="group-inputs">
                                        <label htmlFor="phone" className="">
                                            Phone Number
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="phone"
                                            value={getUserProfile?.phone}
                                            onChange={(e) => userPhoneChange(e)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">
                                <div className="col-6">
                                    <div className="group-inputs mt-3">
                                        <label htmlFor="gender">Gender</label>
                                        <select id="gender" className="form-control" required>
                                            <option key={"male"} value="male" selected={getUserProfile?.gender==='male'?'selected':''}>
                                                Male
                                            </option>
                                            <option key={"female"} value="female" selected={getUserProfile?.gender==='female'?'selected':''}>
                                                Female
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                {/* <div className="col-6">
                                    <div className="group-inputs mt-3">
                                        <label htmlFor="userType">Type</label>
                                        <select id="userType" className="form-control" required>
                                            <option key={"tenant"} value="tenant" selected={getUserProfile?.gender==='tenant'?'selected':''}>
                                                Tenant
                                            </option>
                                            <option key={"owner"} value="owner" selected={getUserProfile?.gender==='owner'?'selected':''}>
                                                Owner
                                            </option>
                                        </select>
                                    </div>
                                </div> */}
                                <div className="col-6">
                                    <div className="group-inputs mt-3">
                                        <label htmlFor="age">Age</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            id="age"
                                            min="18"
                                            max="110"
                                            value={getUserProfile?.age}
                                            onChange={(e) => userAgeChange(e)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="form-group d-flex flex-row mt-3">
                                <label htmlFor="addphoto" className="form-label pt-3">
                                    Add Photo
                                </label>

                                <Upload
                                    // action= {`${config.host}${apiMapper.UPLOAD_FILE}`}
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleFileUpload}
                                >
                                    {fileList.length >= 1 ? null : uploadButton}
                                </Upload>
                                <Modal
                                    open={previewOpen}
                                    title={previewTitle}
                                    footer={null}
                                    onCancel={handleCancel}
                                >
                                    <img
                                        alt="example"
                                        style={{
                                            width: "100%",
                                        }}
                                        src={previewImage}
                                    />
                                </Modal>
                            </div> */}

                            <button type="submit" className="main-btn my-4">
                                Save
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Editprofile