import { useState, useEffect, useRef } from "react";
import apiMapper from "../utils/apiMapper";
import fetcher from "../utils/fetch";
import { useNavigate } from "react-router-dom";
import Spinner from "./spinner";
import "../components/css/registeradd.css";
import Searchbar from "./Serchbar";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import fetchFunction from "../utils/fetch";
import schema from "../utils/schema";

import { useMediaQuery } from "react-responsive";
import config from "../utils/config";
import common from "../utils/common";
import GeoMap from "./geomap";
import { addSlash, removeHostUrl } from "../utils/helper";

const Registered = ({ registrationData, userInfo, userAddData }) => {

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const maxColDesktop = 6;
  const maxColMobile = 2;


  const [tentownradio, setTenantOwnRadio] = useState("tenant");
  const [loading, setLoading] = useState(false);
  const [ownerType, setOwnerType] = useState();
  const [roomTypeCheck, setRoomTypeCheck] = useState({});
  const [checkboxData, setCheckboxData] = useState({});
  const [tenetchecboxData, setTenentCheckboxData] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [rules, setRules] = useState([]);
  const [fileList, setFileList] = useState([]);
  const navigate = useNavigate();
  const selectRef = useRef();
  const [userEditData, setUserEditData] = useState({});
  const [userAdType, setUserAdType] = useState('')
  const [location, setLocation] =  useState(null);

  //tenant schema
  const Tenantschema = schema.tenantschema();

  //owner schema
  const ownerSchema = schema.ownerSchema();


  // photo upload code
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handleFileUpload = async (event) => {

    const file = event.file?.originFileObj;
    if (file){  
      const formData = new FormData();
      formData.append("file", file);

      // make a POST request
      const uploadResponse = await fetchFunction.upLoadFile(
        apiMapper.UPLOAD_FILE,
        formData
      );
      if ([200, 201].includes(uploadResponse?.status)) {
        const image = uploadResponse.data.files[0];


        const imagePriviewData = {
          uid: image.split("/").reverse()[0].split(".")[0],
          name: image.split("/").reverse()[0],
          status: "done",
          url: removeHostUrl(image),
        };

        setFileList([...fileList, imagePriviewData]);

      }
    } else if (event?.file?.status === 'removed'){
      const imageUrl = removeHostUrl(event?.file?.url, true);
      const deletePayload = {"files": [imageUrl]}
      const deleteImageResp = await fetchFunction.delete(apiMapper.UPLOAD_FILE, deletePayload);
      if (deleteImageResp?.status === 200){
        setFileList(fileList.filter((e)=>{
          if(e.url !== addSlash(imageUrl)) return true;
        }))
      }
      
    }
  };

  async function getRule() {
    const ruleData = await fetcher.get(apiMapper.GET_RULES);

    if ([200, 201].includes(ruleData?.status)) {

      if (ruleData?.data?.data) setRules(ruleData?.data?.data);
    }
  }

  useEffect(()=>{
    getRule()
    if (userAddData) {
      setUserAdType(userAddData.userAdType);
    }
    if(userAddData){
      setUserEditData(userAddData?.data || {});
    }
  }, [])

  async function updateEditValuesOwner(){
    let schemaLocal = null;

    if (userAdType=== 'owner') {
      schemaLocal = await schema.ownerSchema();
    } else if (userAdType === 'tenant') {
      schemaLocal = await schema.tenantschema();
    }

    if (Object.keys(userEditData).length){

      var forms = document.querySelectorAll(`.${userAdType}FormSelector`);
      if (forms.length){
        const form = forms[0];
        (Object.keys(schemaLocal) || []).map((key)=>{
          if (key === 'city'){
            return
          }

          if (key === 'rules'){
            return
          }

          if(schemaLocal[key]?.values){
            const values = schemaLocal[key]?.values || [];

            values.map((subArray, i)=>{
              const value =  subArray.value;
              const subkey = subArray.id;
              if(subArray?.inputArray){
                // input array code here
                const matchedData =  userEditData?.[key].filter((e)=>{
                  if(e?.[subkey] === value) return true
                })
                if (matchedData.length) {
                  if (['radio', 'checkbox'].includes(form?.[subkey + (i +1)]?.type)) {
                    form[subkey + (i +1)].click();
                  } 
                  (subArray?.inputArray || []).map((inputField)=>{
                    form[inputField+ (i+1)].value = matchedData[0][inputField];
                  })
                }
                

              }else {
                if(typeof((userEditData?.[key])) === 'object'){
                  const matchedData =  userEditData?.[key].filter((e)=>{
                    if(e?.[subkey] === value) return true
                  })
                  if (matchedData.length) {
                    if (['radio', 'checkbox'].includes(form?.[subkey + (i +1)]?.type)) {
                      form[subkey + (i +1)].click();
                    } 
                  }
                } else if (userEditData?.[key] === value) {
                  if (['radio', 'checkbox'].includes(form?.[subkey + (i +1)]?.type)) {
                    form[subkey + (i +1)].click();
                  }
                }
              }
            })
          } else if(schemaLocal[key].id) {

            const subkey = schemaLocal[key].id;
            if (userEditData?.[subkey] && form?.[subkey]) form[subkey].value =  userEditData?.[subkey];

          }

        })

       //location
       if(userEditData?.location) {
        setLocation(userEditData?.location);
       }

        // city
        if (userEditData?.city){
          setCity(userEditData?.city._id);
          // form.city.value = `${userEditData?.city.name}, ${userEditData.city.countryCode}`
        }
        // photos
        const photos = [];
        (userEditData?.photos || []).map((e)=>{
          const imagePriviewData = {
            uid: e.split("/").reverse()[0].split(".")[0],
            name: e.split("/").reverse()[0],
            status: "done",
            url: e,
          };
          photos.push(imagePriviewData);
          
        })
        if(photos.length) setFileList(photos);

      }
    }

  }
  
  useEffect(() => {
    
    if(Object.keys(userEditData).length) updateEditValuesOwner();
    
  }, [userEditData, userAdType ])


  useEffect(()=>{ 
     // rules
     if(userAdType === 'owner'){
      var forms = document.querySelectorAll('.ownerFormSelector');
      if(forms.length){
        const form = forms[0];
        const ruleLength =  (rules || []).length;
        (userEditData?.rules || []).map((e)=>{
          Array.apply(null, Array(ruleLength)).map((j, i)=>{
            if(form['rule'+ (i+1)]?.value === e._id){
              form['rule'+ (i+1)].click();
            }
          })

        })
      }
     }
     
     
  }, [rules])

  const userHandler = (user) => {
    setTenantOwnRadio(user);
  };

  // tennant checkbox handler
  const handlerTenantRoomtypeClick = (event) => {

    const data = { ...tenetchecboxData };

    if (event.target.checked) {
      data[event.target.id] = true;
    } else {
      data[event.target.id] = false;
    }
    setTenentCheckboxData({ ...data });
  };


  const formDataParser = (schema, event) => {
    const finalData = {};
    for (var key in schema) {
      if (schema[key].type === String) {
        finalData[key] = event.target?.[key]?.value;
      } else if (schema[key].type === Array) {
        const arrayElements = schema[key]?.values || [];
        finalData[key] = [];

        for (let index = 0; index < arrayElements.length; index++) {
          const element = arrayElements[index];
          const elementId = element.id + (index + 1);

          if (event.target.elements?.[elementId]?.checked) {
            const elementData = {};
            elementData[element.id] = event.target.elements?.[elementId]?.value;
            if (element.inputArray) {
              element.inputArray.map((inputarrEle) => {
                elementData[inputarrEle] =
                  event.target.elements?.[inputarrEle + (index + 1)]?.value;
              });
            }

            finalData[key].push(elementData);
          }
        }
      }
    }
    return finalData;
  };

  const submitRegistrationForm = async (event, userType) => {
    // setLoading(true);
    var finalData = null;
    const consolidateData = {
      ...registrationData,
      ...userInfo,
    };

    if (userType === "tenant") {
      finalData = formDataParser(Tenantschema, event);
      finalData.cityId = city;
      consolidateData.tenant = finalData;
    } else {
      let photos = [];
      fileList.map((ele, i) => {
        photos.push(ele.url);
        return null;
      });
      finalData = formDataParser(ownerSchema, event);
      finalData.cityId = city;
      if (location) finalData.location = location;
      consolidateData.owner = finalData;
      consolidateData.owner.photos = photos;
      
      finalData.rules = (finalData.rules || []).map((e)=>{return e.rule})
    }
    let registerResp = null;
    if (Object.keys(userEditData).length && userAdType){
      if(userAdType === 'owner'){
        registerResp = await fetcher.put(
          apiMapper.UPDATE_OWNER,
          consolidateData.owner,
          config.host,
          common.getHeaders()
        );
      } else {
        registerResp = await fetcher.put(
          apiMapper.UPDATE_TENANT,
          consolidateData.tenant,
          config.host,
          common.getHeaders()
        );
      }
      
    } else {
      registerResp = await fetcher.post(apiMapper.REGISTER,consolidateData);
    }
    

    if ([200, 201].includes(registerResp?.status) && registerResp?.data?.status === "SUCCESS") {
      setLoading(true);
      const token = registerResp?.data?.data?.token;
      if (token) {
        localStorage.setItem("Authorization", token);
        localStorage.setItem("name", consolidateData.name);
        localStorage.setItem("email", consolidateData.email);
        localStorage.setItem("profileImg", consolidateData.profileImg);
        localStorage.setItem("gender", consolidateData.gender);
      }
      setTimeout(() => {
        navigate("/");
        navigate(0);
      }, 2000);
    } else {
      setLoading(false);
    }
  };

  // onSubmit tenant Form
  const onSubmitRegisterAddTenantHandler = async (event) => {
    event.preventDefault();
    await submitRegistrationForm(event, "tenant");
  };





  //OWnerType Handler

  const ownerTypeHandler = (event) => {

    setOwnerType(event.target.value);
  };

  // owner handlerRoomTypeCheck

  const handlerRoomTypeCheck = (event) => {
    const { value, checked } = event.target;
    const data = { ...roomTypeCheck };
    if (value) {
      data[value] = checked;
      setRoomTypeCheck(data);
    }
  };

  const handlerAmenitiesClick = (event) => {

    const data = { ...checkboxData };

    if (event.target.checked) {
      data[event.target.id] = true;
    } else {
      data[event.target.id] = false;
    }
    setCheckboxData({ ...data });
  };


  //location searchbar Handler
  const [city, setCity] = useState();
  const locationSearchHandler = (searchvalue) => {
    if (searchvalue.cityId) setCity(searchvalue.cityId);
  };

  // onSubmit Owner Form
  const onSubmitRegisterAddOwnerHandler = async (event) => {
    event.preventDefault();


    await submitRegistrationForm(event, "owner");
  };

  const selectHandler = (event) => {
    

    if (event.target.className.includes('active')) {
      event.target.className = event.target.className.replace('active', '').trim();
    } else {
      event.target.className = event.target.className.trim() + ' active';
    }
  };

  const geoDataHandler = (address, lat, lng)=>{
    setLocation({
      address,
      lat,
      lng
    })

  }

  return (
    <>
      <div className="min-vh-100 bgcolor registerADD d-flex justify-content-md-center px-0 pt-2">
        {loading ? (
          <>
            <div className="container success-message ">
              <div className="thanks-message text-center" id="text-message"> <img src="https://i.imgur.com/O18mJ1K.png" width="100" alt="message" className="mb-4" />
                <h3>{Object.keys(userEditData).length ? "Ad Updation Done" : "Registration Done!"}</h3> <span> You will be redirected to Home screen now!</span>
              </div>
            </div>

            {/* <Spinner /> */}
          </>

        ) : (
          <>
            <div className="container" style={{ backgroundColor: "white" }}>
              <div className="heading"></div>
              {userAdType === "owner" ? (<><h1>Owner Details</h1></>) : (<><h1>List a Room</h1></>)} {!userAddData && <div className="row toggler-tenant-owner justify-content-center">
                <div className="col-lg-6 col-10 ">
                  <div className="tab row">
                    <div className={
                      "col-6 owner " + (tentownradio === "owner" ? "active" : "")
                    }><a
                      href="#/"

                      onClick={() => {
                        userHandler("owner");
                      }}
                    >
                        List a Room
                      </a></div>
                    <div className={
                      "col-6 tenant " + (tentownradio === "tenant" ? "active" : "")
                    }>
                      <a
                        href="#/"

                        onClick={() => {
                          userHandler("tenant");
                        }}
                      >
                        Find a Room
                      </a>
                    </div>


                  </div>
                </div>

              </div>}

              {/* ------1. tennat(true) 2. Owner(false)------ */}
              {((userAdType || tentownradio) === "tenant") ? (
                <>
                  {/* ----tenant Register here---- */}
                  <div className="tenant-registration row d-flex justify-content-center">
                    <form onSubmit={onSubmitRegisterAddTenantHandler} className="tenantFormSelector">
                      {/* ----tenent location---- */}
                      <div className="row">
                        <div className=" col-md-3 col-8 ">
                          <h3 className="pb-2">Location</h3>
                          <div className="input-group  ">
                            {/* <span className="input-group-text loc-icon" >
                            <i className="bi bi-geo-alt"></i>
                          </span> */}
                          {userEditData?.city && <Searchbar handler={locationSearchHandler} defaultValue={userEditData?.city ? `${userEditData?.city.name}, ${userEditData.city.countryCode}`: ""} />  }
                          {!userEditData?.city && <Searchbar handler={locationSearchHandler} />  }

                            {/* {<Searchbar handler={locationSearchHandler} defaultValue={userEditData?.city ? `${userEditData?.city.name}, ${userEditData.city.countryCode}`: ""} />} */}
                          </div>
                        </div>
                      </div>

                      {/* ----tenant roomType-- */}
                      <div className="row mb-4">
                        <h3>Room Type*</h3>

                        {Array.apply(
                          null,
                          Array(
                            parseInt(
                              Tenantschema.roomTypes.values.length / (isDesktop ? maxColDesktop : maxColMobile) + 1
                            )
                          )
                        ).map((e, rowNumber) => {
                          return (
                            <>
                              <div className="row roomType-main">
                                {Tenantschema.roomTypes.values
                                  .slice(rowNumber * (isDesktop ? maxColDesktop : maxColMobile), (rowNumber + 1) * (isDesktop ? maxColDesktop : maxColMobile))
                                  .map((element, i) => {
                                    return (
                                      <>
                                        <div className={"text-center " + (isDesktop ? 'col-2' : 'col-6')}  >
                                          <div className="form-check form-check-inline pt-2">
                                            <input
                                              type="checkbox"
                                              className="btn-check"
                                              id={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                              name={element.id}
                                              value={element.value}
                                              autoComplete="off"
                                              onChange={
                                                handlerTenantRoomtypeClick
                                              }
                                            />
                                            <label
                                              className=" select-img-label btn form-check"
                                              htmlFor={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                            >
                                              <div>
                                                {" "}
                                                <img
                                                  className={
                                                    "icon-class" +
                                                    (tenetchecboxData?.[
                                                      element.id +
                                                      (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                    ]
                                                      ? "-checked"
                                                      : "")
                                                  }
                                                  src={element.img}
                                                  alt={element.meta}
                                                ></img>
                                              </div>
                                              <p
                                                className={
                                                  "icon-class-text" +
                                                  (tenetchecboxData?.[
                                                    element.id +
                                                    (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                  ]
                                                    ? "-checked"
                                                    : "")
                                                }
                                              >
                                                {element.meta}
                                              </p>
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          );
                        })}
                      </div>
                      {/* ---tenant profession and organisation-- */}
                      <div className="row mb-4 mb-md-2">
                        <h3 className="pb-2">PROFESSION & ORGANIZATION</h3>
                        <div className="col-lg-3 col-md-3">
                          <div className="group-inputs">
                            <label htmlFor="profesion">
                              <p>PROFESSION</p>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="profesion"
                              name="profesion"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 ">
                          <div className="group-inputs pt-2 pt-lg-0">
                            <label htmlFor="organisation">Organization</label>
                            <input
                              type="text"
                              className="form-control"
                              id="organisation"
                              name="organisation"
                            />
                          </div>
                        </div>
                      </div>
                      {/* -----teant futnishing type--- */}
                      <div className="row mb-4 ">
                        <h3>FURNISHING TYPE</h3>
                        {/* <div className={"amenities" + (isDesktop ? " row":  " row-cols-2")}>
                          {Tenantschema.furnishingTypes.values.map(
                            (element, i) => {
                              return (
                                <>
                                  <div
                                    className={
                                      "amenties-main col-6 text-center"
                                    }
                                  >
                                    <div className=" pt-2">
                                      <input
                                        type="checkbox"
                                        className="btn-check"
                                        id={element.id + (i + 1)}
                                        name={element.id}
                                        value={element.value}
                                        autoComplete="off"
                                        onChange={handlerTenantRoomtypeClick}
                                      />
                                      <label
                                        className="btn form-check"
                                        htmlFor={element.id + (i + 1)}
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            className={
                                              "icon-class" +
                                              (tenetchecboxData?.[
                                                element.id + (i + 1)
                                              ]
                                                ? "-checked"
                                                : "")
                                            }
                                            src={element.img}
                                            alt={element.meta}
                                          ></img>
                                        </div>
                                        <p
                                          className={
                                            "icon-class-text" +
                                            (tenetchecboxData?.[
                                              element.id + (i + 1)
                                            ]
                                              ? "-checked"
                                              : "")
                                          }
                                        >
                                          {element.meta}
                                        </p>
                                      </label>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div> */}
                        {Array.apply(
                          null,
                          Array(
                            parseInt(
                              Tenantschema.roomTypes.values.length / (isDesktop ? maxColDesktop : maxColMobile) + 1
                            )
                          )
                        ).map((e, rowNumber) => {
                          return (
                            <>
                              <div className="row roomType-main">
                                {Tenantschema.furnishingTypes.values
                                  .slice(rowNumber * (isDesktop ? maxColDesktop : maxColMobile), (rowNumber + 1) * (isDesktop ? maxColDesktop : maxColMobile))
                                  .map((element, i) => {
                                    return (
                                      <>
                                        <div className={"text-center " + (isDesktop ? 'col-2' : 'col-6')}  >
                                          <div className="form-check form-check-inline pt-2">
                                            <input
                                              type="checkbox"
                                              className="btn-check"
                                              id={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                              name={element.id}
                                              value={element.value}
                                              autoComplete="off"
                                              onChange={
                                                handlerTenantRoomtypeClick
                                              }
                                            />
                                            <label
                                              className="select-img-label btn form-check"
                                              htmlFor={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                            >
                                              <div>
                                                {" "}
                                                <img
                                                  className={
                                                    "icon-class" +
                                                    (tenetchecboxData?.[
                                                      element.id +
                                                      (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                    ]
                                                      ? "-checked"
                                                      : "")
                                                  }
                                                  src={element.img}
                                                  alt={element.meta}
                                                ></img>
                                              </div>
                                              <p
                                                className={
                                                  "icon-class-text" +
                                                  (tenetchecboxData?.[
                                                    element.id +
                                                    (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                  ]
                                                    ? "-checked"
                                                    : "")
                                                }
                                              >
                                                {element.meta}
                                              </p>
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          );
                        })}
                      </div>

                      <div className="row mb-4">
                        <label htmlFor="floatingTextarea2">Description</label>
                        <div className={"pt-1 " + (isDesktop ? "col-6" : "col-10")}>
                          <div className="form-floating">
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="description"
                              style={{ height: "100%", width: "100%" }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div className="col-8">
                          <button type="submit" className="btn btn-primary mb-2">
                            Register
                          </button>
                        </div>

                      </div>

                    </form>
                  </div>
                </>
              ) : (
                <>
                  <div className="owner-registration row d-flex justify-content-center " >
                    <form onSubmit={onSubmitRegisterAddOwnerHandler} className="ownerFormSelector">
                      <div
                        className="row mt-4"
                        style={{ backgroundColor: "white" }}
                      >
                        <h3>OWNER TYPE*</h3>
                        {ownerSchema.ownerType.values.map((ele, i) => {
                          return <div className={isDesktop ? "col-3" : "col-4"}>

                            <label
                              className=" select-label"
                              htmlFor={ele.id + (i + 1)}
                            >
                              <input
                                type="radio"
                                className="btn-check"
                                name={ele.id}
                                id={ele.id + (i + 1)}
                                value={ele.value}
                                onChange={ownerTypeHandler}
                              />
                              <span>{ele.meta}</span>

                            </label>
                          </div>
                        })}

                      </div>

                      {/* Map  */}
                      <div
                        className={
                          "row mt-5" 
                          // +
                          // (ownerType !== ownerSchema.ownerType.values[0].value
                          //   ? " d-none"
                          //   : "")
                        }
                        style={{ backgroundColor: "white", width: "100%" }}
                      >
                        <h3 className="pb-2">Map</h3>
                        <div className={isDesktop ? "col-3 " : "col-10"}>
                          <div className="row">
                          <GeoMap geoDataHandler={geoDataHandler} userEditData={userEditData}/>

                          </div>

                          {/* <div className={"row mt-4"}>
                          <div className="group-inputs mb-4">
                            <label htmlFor="pgName">PG Name</label>
                            <input
                              type="text" 
                              className="form-control"
                              id="pgName"
                              name="pgName"
                              />
                          </div>
                          </div> */}
                          
                          
                        </div>
                      </div>

                      

                      {/* Location  */}

                      <h3>Location</h3>
                      <div
                        className="row row-cols-2 mt-2"
                        style={{ backgroundColor: "white", width: "100%" }}
                      >
                        <div className=" col-md-3 col-6 ">
                          <div className="input-group city-search-input">
                            {/* <span className="input-group-text loc-icon" >
                            <i className="bi bi-geo-alt"></i>
                          </span> */}
                            {<Searchbar handler={locationSearchHandler} defaultValue={userEditData?.city ? `${userEditData?.city.name}, ${userEditData.city.countryCode}`: ""} />}
                          </div>
                        </div>
                        <div className=" col-md-3 col-6 ">
                          <div className="group-inputs mb-4">
                            <label htmlFor="locality">Area</label>
                            <input
                              type="text"
                              className="form-control"
                              id="locality"
                              name="locality"
                              aria-describedby="areaHelp"
                            />
                          </div>
                        </div>
                        {/* <div
                          className={
                            " col-md-3 col-6" +
                            (ownerType !== ownerSchema.ownerType.values[0].value
                              ? " d-none"
                              : "")
                          }
                        >
                          <div className="group-inputs mb-4">
                            <label htmlFor="operatingSince">
                              Operating Since
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              id="operatingSince"
                              name="operatingSince"
                              aria-describedby="areaHelp"
                            />
                          </div>
                        </div> */}
                        {/* <div
                          className={
                            " col-md-3 col-6" +
                            (ownerType !== ownerSchema.ownerType.values[0].value
                              ? " d-none"
                              : "")
                          }
                        >
                          <div className="group-inputs mb-4">
                            <label htmlFor="numberBed">No of beds</label>
                            <input
                              type="number"
                              className="form-control"
                              id="numberBed"
                              name="numberBed"
                              aria-describedby="areaHelp"
                            />
                          </div>
                        </div> */}
                      </div>

                      {/* ---ownerRoomtype----- */}

                      {/* <div className="row mb-4">
                    <h3>
                      Sharing type you are looking For(You can choose Multiple)*
                    </h3>

                    {Array.apply(null, Array(parseInt((ownerSchema.roomTypes.values.length / 6) + 1))).map((e, rowNumber) => {
                      return <>
                        <div className="row amenities">
                          {ownerSchema.roomTypes.values.slice(rowNumber * 6, (rowNumber + 1) * 6).map((element, i) => {
                            return <>

                              <div className={"amenties-main col-2 text-center"}>
                                <div className="form-check form-check-inline pt-2">
                                  <input
                                    type="checkbox"
                                    className="btn-check"
                                    id={element.id + (rowNumber * 6 + i + 1)}
                                    name={element.id}
                                    value={element.value}
                                    autoComplete="off"
                                    onChange={handlerAmenitiesClick}
                                  />
                                  <label
                                    className="btn form-check"
                                    htmlFor={element.id + (rowNumber * 6 + i + 1)}
                                  >
                                    <div> <img className={"icon-class" + (checkboxData?.[element.id + (rowNumber * 6 + i + 1)] ? "-checked" : "")} src={element.img} alt={element.meta}></img></div>
                                    <p className={"icon-class-text" + (checkboxData?.[element.id + (rowNumber * 6 + i + 1)] ? "-checked" : "")}>{element.meta}</p>
                                  </label>
                                </div>
                              </div>
                            </>
                          }
                          )}
                        </div>
                      </>

                    })}
                  </div> */}

                      <div
                        className="row mt-4"
                        style={{ backgroundColor: "white", width: "100%" }}
                      >
                        <h3>
                          Sharing type you are looking For(You can choose
                          Multiple)*
                        </h3>
                        <div className={"row row-cols-2 amenities" + (isDesktop ? "" : " row-cols-2")}>
                          {ownerSchema.roomType.values.map((element, i) => {
                            return (
                              <>
                                <div
                                  className={
                                    "col roomType1-main amenties-main  " +
                                    (roomTypeCheck?.[element.value] === true
                                      ? "d-none"
                                      : "")
                                  }
                                >
                                  <div className="form-check form-check-inline pt-2">
                                    <input
                                      type="checkbox"
                                      className="btn-check"
                                      name={element.id}
                                      id={element.id + (i + 1)}
                                      value={element.value}
                                      autoComplete="off"
                                      onChange={handlerRoomTypeCheck}
                                    />
                                    <label
                                      className="btn btn-outline-primary form-check"
                                      htmlFor={element.id + (i + 1)}
                                    >
                                      <div>
                                        {" "}
                                        <img
                                          className={
                                            "icon-class" +
                                            (checkboxData?.[element.id]
                                              ? "-checked"
                                              : "")
                                          }
                                          src={element.img}
                                          alt={element.meta}
                                        ></img>
                                      </div>
                                      <p
                                        className={
                                          "icon-class-text" +
                                          (checkboxData?.[element.id]
                                            ? "-checked"
                                            : "")
                                        }
                                      >
                                        {element.meta}
                                      </p>
                                    </label>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </div>

                        {ownerSchema.roomType.values.map((element, i) => {
                          return (
                            <>
                              <div
                                className={
                                  "row " +
                                  (roomTypeCheck?.[element.value] === false ||
                                    !roomTypeCheck?.[element.value]
                                    ? "d-none"
                                    : "")
                                }
                              >
                                <div className="col-12 col-md-3">
                                  <div className="row floating-button-sharing-type">
                                    <div className="col text-center">
                                      <div className="form-check form-check-inline pt-2">
                                        {/* <input
                                    type="checkbox"
                                    className="btn-check"
                                    name={element.id}
                                    id={element.id + (i + 1)}
                                    value={element.value}
                                    autoComplete="off"
                                    onChange={handlerRoomTypeCheck}

                                  /> */}
                                        <label
                                          className="btn  form-check"
                                          htmlFor={element.id + (i + 1)}
                                        >
                                          <i className="bi bi-trash3-fill" style={{color: "red", "font-size": "24px"}}></i>
                                          

                                        </label>
                                      </div>
                                    </div>
                                    <div className="col text-center" style={{'align-items': 'center', 'display': 'inline-flex'}}>{element.meta} </div>
                                  </div>
                                </div>

                                {element.inputArray.map((inputValue) => {
                                  return (
                                    <>
                                      <div
                                        className={
                                          "col-6 col-md-3" +
                                          (ownerType !==
                                            ownerSchema.ownerType.values[0].value &&
                                            inputValue === element.inputArray[2]
                                            ? "d-none"
                                            : "")
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          id={inputValue + (i + 1)}
                                          name={inputValue + (i + 1)}
                                          placeholder={inputValue
                                            .replace("-", " ")
                                            .toLocaleLowerCase()}
                                          aria-describedby="locationHelp"
                                        />
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })}
                      </div>

                      {/* --ownerprofession and organisation---- */}
                      <div className="row mb-4 mb-md-2">
                        <h3 className="pb-2">PROFESSION & ORGANIZATION</h3>
                        <div className="col-lg-3 col-md-3">
                          <div className="group-inputs">
                            <label htmlFor="profesion">
                              <p>PROFESSION</p>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="profesion"
                              name="profesion"
                            />
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-3 ">
                          <div className="group-inputs pt-2 pt-lg-0">
                            <label htmlFor="organisation">Organization</label>
                            <input
                              type="text"
                              className="form-control"
                              id="organisation"
                              name="organisation"
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="row mb-3">
                    <h3>PROFESSION & ORGANIZATION</h3>
                    <div className="col-3">
                      <label htmlFor="location" className="form-label">
                        <p>PROFESSION</p>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="profesion"
                        name="profesion"
                        aria-describedby="locationHelp"
                      />
                    </div>
                    <div className="col-3">
                      <label htmlFor="organization" className="form-label">
                        <p>Organization</p>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="organisation"
                        name="profesion"
                        aria-describedby="locationHelp"
                      />
                    </div>
                  </div> */}

                      {/* owner furnishing types */}

                      <div className="row mb-4 ">
                        <h3>FURNISHING TYPE</h3>
                        <div className="row amenities">
                          {ownerSchema.furnishingTypes.values.map(
                            (element, i) => {
                              return (
                                <>
                                  <div
                                    className={"amenties-main col-6 col-md-2 text-center"}
                                  >
                                    <div className="form-check form-check-inline pt-2">
                                      <input
                                        type="checkbox"
                                        className="btn-check"
                                        id={element.id + (i + 1)}
                                        name={element.id}
                                        value={element.value}
                                        autoComplete="off"
                                        onChange={handlerAmenitiesClick}
                                      />
                                      <label
                                        className="btn form-check"
                                        htmlFor={element.id + (i + 1)}
                                      >
                                        <div>
                                          {" "}
                                          <img
                                            className={
                                              "icon-class" +
                                              (checkboxData?.[
                                                element.id + (i + 1)
                                              ]
                                                ? "-checked"
                                                : "")
                                            }
                                            src={element.img}
                                            alt={element.meta}
                                          ></img>
                                        </div>
                                        <p
                                          className={
                                            "icon-class-text" +
                                            (checkboxData?.[element.id + (i + 1)]
                                              ? "-checked"
                                              : "")
                                          }
                                        >
                                          {element.meta}
                                        </p>
                                      </label>
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                      {/* <div className="row mb-4">
                    <h3>FURNISHING TYPE</h3>

                    {ownerSchema.furnishingType.values.map((element, i) => {
                      return <>
                        <div className={"col-" + parseInt(6 / ownerSchema.furnishingType.values.length)}>
                          <div className="form-check form-check-inline pt-2">
                            <input
                              type="radio"
                              className="btn-check"
                              id={element.id + (i + 1)}
                              name={element.id}
                              value={element.value}
                              autoComplete="off"
                            />
                            <label
                              className="btn btn-outline-primary form-check"
                              htmlFor={element.id + (i + 1)}
                            >

                              {element.meta}
                            </label>
                          </div>
                        </div>
                      </>
                    }
                    )}
                  </div> */}

                      {/* owner Offer For types */}
                      <div className="row mb-4">
                        <h3>Offering for</h3>

                        {ownerSchema.offerFor.values.map((ele, i) => {
                          return (
                            <>
                              <div className={isDesktop ? "col-3" : "col-4"}>

                                <label
                                  className=" select-label"
                                  htmlFor={ele.id + (i + 1)}
                                >
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name={ele.id}
                                    id={ele.id + (i + 1)}
                                    value={ele.value}
                                  />
                                  <span>{ele.meta}</span>

                                </label>
                              </div>
                            </>
                          );
                        })}
                      </div>

                      {/* House Rule For types */}
                      <div className="row mb-4 ">
                        <h3>House Rules</h3>
                        <div className="conatainer mx-2">
                          {rules.map((element, i) => {
                            return (
                              <>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={element._id}
                                    name={ownerSchema.rules.values[0].id}
                                    id={ownerSchema.rules.values[0].id + (i + 1)}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={ownerSchema.rules.values[0].id + (i + 1)}
                                  >
                                    {element.name}
                                  </label>

                                  {/* <div className="form-check form-check-inline pt-2">
                            <input
                              type="checkbox"
                              className="btn-check"
                              id={element.id + (i + 1)}
                              name={element.id}
                              value={element.value}
                              autoComplete="off"
                            />
                            <label
                              className="btn btn-outline-primary form-check"
                              htmlFor={element.id + (i + 1)}
                            >
                              {element.meta}
                            </label>
                          </div> */}
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                      {/* Food type For types */}
                      <div className="row mb-4">
                        <h3>Food Offered</h3>

                        {ownerSchema.foodTypes.values.map((ele, i) => {
                          return (
                            <>
                              <div className={isDesktop ? "col-3" : "col-4"}>

                                <label
                                  className=" select-label"
                                  htmlFor={ele.id + (i + 1)}
                                >
                                  <input
                                    type="radio"
                                    className="btn-check"
                                    name={ele.id}
                                    id={ele.id + (i + 1)}
                                    value={ele.value}
                                  />
                                  <span>{ele.meta}</span>

                                </label>
                              </div>
                            </>
                          );
                        })}
                      </div>

                      {/* --FoodService-- */}
                      <div className="row mb-4 foodservice">
                        <h3>Food Service</h3>

                        {ownerSchema.foodServices.values.map((ele, i) => {
                          return (
                            <>
                              <div className={isDesktop ? "col-3" : "col-4"}>

                                <label
                                  className=" select-label"
                                  htmlFor={ele.id + (i + 1)}
                                >
                                  <input
                                    type="checkbox"
                                    className="btn-check"
                                    name={ele.id}
                                    id={ele.id + (i + 1)}
                                    value={ele.value}
                                  />
                                  <span>{ele.meta}</span>

                                </label>
                              </div>
                            </>
                          );
                        })}
                      </div>

                      {/* Amenties For types */}
                      <div className="row mb-4">
                        <h3>Amenities</h3>

                        {Array.apply(
                          null,
                          Array(
                            parseInt(ownerSchema.amenities.values.length / (isDesktop ? maxColDesktop : maxColMobile) + 1)
                          )
                        ).map((e, rowNumber) => {
                          return (
                            <>
                              <div className="row amenities">
                                {ownerSchema.amenities.values
                                  .slice(rowNumber * (isDesktop ? maxColDesktop : maxColMobile), (rowNumber + 1) * (isDesktop ? maxColDesktop : maxColMobile))
                                  .map((element, i) => {
                                    return (
                                      <>
                                        <div
                                          className={
                                            "amenties-main col-6 col-md-2 text-center"
                                          }
                                        >
                                          <div className="form-check form-check-inline pt-2">
                                            <input
                                              type="checkbox"
                                              className="btn-check"
                                              id={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                              name={element.id}
                                              value={element.value}
                                              autoComplete="off"
                                              onChange={handlerAmenitiesClick}
                                            />
                                            <label
                                              className="btn  form-check"
                                              htmlFor={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                            >
                                              <div>
                                                {" "}
                                                <img
                                                  className={
                                                    "icon-class" +
                                                    (checkboxData?.[
                                                      element.id +
                                                      (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                    ]
                                                      ? "-checked"
                                                      : "")
                                                  }
                                                  src={element.img}
                                                  alt={element.meta}
                                                ></img>
                                              </div>
                                              <p
                                                className={
                                                  "icon-class-text" +
                                                  (checkboxData?.[
                                                    element.id +
                                                    (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                  ]
                                                    ? "-checked"
                                                    : "")
                                                }
                                              >
                                                {element.meta}
                                              </p>
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          );
                        })}
                      </div>

                      {/* Building Amenties For types */}
                      <div className="row mb-4">
                        <h3>Building Amenities</h3>

                        {Array.apply(
                          null,
                          Array(
                            parseInt(
                              ownerSchema.buildingAmenities.values.length / (isDesktop ? maxColDesktop : maxColMobile) + 1
                            )
                          )
                        ).map((e, rowNumber) => {
                          return (
                            <>
                              <div className="row amenities">
                                {ownerSchema.buildingAmenities.values
                                  .slice(rowNumber * (isDesktop ? maxColDesktop : maxColMobile), (rowNumber + 1) * (isDesktop ? maxColDesktop : maxColMobile))
                                  .map((element, i) => {
                                    return (
                                      <>
                                        <div
                                          className={
                                            "amenties-main col-6 col-md-2 text-center"
                                          }
                                        >
                                          <div className="form-check form-check-inline pt-2">
                                            <input
                                              type="checkbox"
                                              className="btn-check"
                                              id={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                              name={element.id}
                                              value={element.value}
                                              autoComplete="off"
                                              onChange={handlerAmenitiesClick}
                                            />
                                            <label
                                              className="btn  form-check"
                                              htmlFor={
                                                element.id +
                                                (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                              }
                                            >
                                              <div>
                                                {" "}
                                                <img
                                                  className={
                                                    "icon-class" +
                                                    (checkboxData?.[
                                                      element.id +
                                                      (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                    ]
                                                      ? "-checked"
                                                      : "")
                                                  }
                                                  src={element.img}
                                                  alt={element.meta}
                                                ></img>
                                              </div>
                                              <p
                                                className={
                                                  "icon-class-text" +
                                                  (checkboxData?.[
                                                    element.id +
                                                    (rowNumber * (isDesktop ? maxColDesktop : maxColMobile) + i + 1)
                                                  ]
                                                    ? "-checked"
                                                    : "")
                                                }
                                              >
                                                {element.meta}
                                              </p>
                                            </label>
                                          </div>
                                        </div>
                                      </>
                                    );
                                  })}
                              </div>
                            </>
                          );
                        })}
                      </div>

                      {/* ----Browse  property pic----- */}

                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-group  mt-3">
                            <label htmlFor="addphoto" className="form-label">
                              ADD PHOTOS OF PROPERTY
                            </label>

                            <Upload
                              // action= {`${config.host}${apiMapper.UPLOAD_FILE}`}
                              listType="picture-card"
                              fileList={fileList}
                              onPreview={handlePreview}
                              onChange={handleFileUpload}
                            >
                              {fileList.length > 8 ? null : uploadButton}
                            </Upload>
                            <Modal
                              open={previewOpen}
                              title={previewTitle}
                              footer={null}
                              onCancel={handleCancel}
                            >
                              <img
                                alt="example"
                                style={{
                                  width: "100%",
                                }}
                                src={previewImage}
                              />
                            </Modal>
                          </div>
                        </div>
                      </div>

                      {/* Description */}
                      <div className="row mb-4">
                        <label htmlFor="floatingTextarea2">Description</label>
                        <div className="col-6 pt-1">
                          <div className="form-floating">
                            <textarea
                              className="form-control"
                              placeholder="Leave a comment here"
                              id="description"
                              style={{ height: "100%" }}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary ">
                        Register
                      </button>
                    </form>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );

};
export default Registered;
