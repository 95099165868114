
import "../components/css/filter.css";
import schema from "../utils/schema";
import { useEffect, useState } from "react";



export const Filter = ({pushfilterData, userType}) => {

    const [filterData, setFilterData] = useState({});

    const [userSchema,setUserSchema]=useState({});
    const [userFilterOrder, setUserFilterOrder]  = useState([]);

    useEffect(()=>{
        
        if(userType==='owner'){
            const ownerFilterSchema = schema.filter.ownerSchema();
            const ownerFilterOrder = ['offerFor', 'roomTypes', 'furnishingTypes']; // userType', 'amenities','buildingAmenities'
            setUserSchema(ownerFilterSchema);
            setUserFilterOrder(ownerFilterOrder);
        }
        else{
            const tenantFilterSchema = schema.filter.ownerSchema();
            const tenantFilterOrder = ['offerFor', 'roomTypes', 'furnishingTypes']; // userType', 'amenities','buildingAmenities'
            setUserSchema(tenantFilterSchema);
            setUserFilterOrder(tenantFilterOrder);
        }
    }, [])
    
    useEffect(() => {
        pushfilterData(filterData)
    }, [filterData])


    const handleFilter = (event) => {
        const { value, checked, name } = event.target;
    
        setFilterData(prevFilterData => {
            const rootID = name.split('.')[0];
            const subID = name.split('.')[1];
    
            // Make a copy of the previous filter data
            let finalFilterData = { ...prevFilterData };
    
            // Initialize the category array if it doesn't exist
            if (!finalFilterData[rootID]) {
                finalFilterData[rootID] = [];
            }
    
            // If checked, add the value to the filter data; if unchecked, remove it
            if (checked) {
                finalFilterData[rootID] = [...finalFilterData[rootID], { [subID]: value }];
            } else {
                // Remove the filter from the filter data
                finalFilterData[rootID] = finalFilterData[rootID].filter(fEle => fEle?.[subID] !== value);
            }
    
            // Check if all checkboxes within a category are unchecked
            const isCategoryUnchecked = Object.keys(finalFilterData).every(key => finalFilterData[key].length === 0);
    
            // If all checkboxes are unchecked, return an empty object to show all data
            if (isCategoryUnchecked) {
                // return {};
            }
    
            return finalFilterData;
        });
    };
    

    return (<>
        <div className="container filter-section">
          
            <div className="seprationboder"></div>

            {/* -------RoomType section-------- */}
            <section>

                {userFilterOrder.map((filterEle,k) => {
                    return <><div className="row">
                        <div className="accordion accordion-flush" id="roomtype">
                            <div className="accordion-item">
                                <h2 className="accordion-header" id="flush-heading-rmtype">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#flush-collapseOne-rmtype" +k}aria-expanded="false" aria-controls="flush-collapseOne-rmtype">
                                        {userSchema?.[filterEle].meta.toLocaleUpperCase()}
                                    </button>
                                </h2>
                                <div id={"flush-collapseOne-rmtype"+k} className="accordion-collapse collapse" aria-labelledby="flush-heading-rmtype" data-bs-parent="#roomtype">
                                    <div className="accordion-body">
                                        {
                                            userSchema?.[filterEle].values.map((subEle, i) => {
                                                return (<>
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value={subEle.value} id={subEle.id + (i + 1)} name={`${filterEle}.${subEle.id}`} onChange={handleFilter} />
                                                        <label className="form-check-label" htmlFor={subEle.id + (i + 1)}>
                                                            {subEle.meta}
                                                        </label>
                                                    </div>
                                                </>)
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        <div className="seprationboder"></div></>
                })}

                <div className="row">
                    <div className="accordion accordion-flush" id="furnishingtype">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="flush-headingOne-furnishtype">
                                {/* <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne-furnishtype" aria-expanded="false" aria-controls="flush-collapseOne-furnishtype">
                                    {tenantFilterSchema.furnishingTypes.meta.toLocaleUpperCase()}
                                </button> */}
                            </h2>
                            <div id="flush-collapseOne-furnishtype" className="accordion-collapse collapse" aria-labelledby="flush-headingOne-furnishtype" data-bs-parent="#furnishingtype">
                                <div className="accordion-body">
                                    {/* {
                                        tenantFilterSchema.furnishingTypes.values.map((furnishtyp, i) => {
                                            return (<>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" value={furnishtyp.value} id={furnishtyp.id + (i + 1)} name="furnishingType" onChange={handleFilter} />
                                                    <label className="form-check-label" htmlFor={furnishtyp.id + (i + 1)}>
                                                        {furnishtyp.meta}
                                                    </label>
                                                </div>
                                            </>)
                                        })
                                    } */}


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div></>)
}

export default Filter
