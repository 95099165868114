import apiMapper from "../utils/apiMapper";
import fetcher from "../utils/fetch";
import { Link, useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import common from "../utils/common";
import { useMediaQuery } from 'react-responsive';

const Loginregistrationmodal = ({ loginDataHandler }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [loading, setLoading] = useState(false);
  const ref = useRef(null);
  const passwordRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    validation();

  }, [])

  const handlerLogin = async (event) => {

    event.preventDefault();
    setLoading(true);
    const email = event.target.email.value;
    const password = event.target.password.value;


    if (email && password) {
      const resp = await fetcher.post(apiMapper.LOGIN, { email, password });

      if ([200, 201].includes(resp?.status)) {

        const respdata = resp.data?.data;
        const token = respdata?.token;
        if (token) {
          localStorage.setItem("Authorization", token);
          localStorage.setItem("name", respdata.name);
          localStorage.setItem("email", respdata.email);
          localStorage.setItem("profileImg", respdata.profileImg);
          localStorage.setItem("gender", respdata.gender);
          // localStorage.setItem("userType", respdata.userType);
          if (respdata.membershipStatus) localStorage.setItem("membershipStatus", respdata.membershipStatus);
          localStorage.setItem("id", respdata.id);
          localStorage.setItem("gender", respdata.gender);
          if (respdata.membershipExpiryDate) localStorage.setItem("membershipExpiryDate", respdata.membershipExpiryDate);

          const currentStatePopup = ref.current;

          setTimeout(() => {
            currentStatePopup.click();
            setLoading(false);
            loginDataHandler(respdata);
            common.resetForm(event);
            navigate(0);
          }, 1000);
        }

      } else {
        setLoading(false);
        event.target.email.classList.add('is-invalid');
      }
    }

  };
  const handlerRegisterNav = async () => {
    ref.current.click();
    if (common) navigate('/register');

  };
  const validation = () => {
    (function () {

      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.querySelectorAll('.needs-validation')

      // Loop over them and prevent submission
      Array.prototype.slice.call(forms)
        .forEach(function (form) {
          form.addEventListener('submit', function (event) {

            if (!form.checkValidity()) {
              event.preventDefault()
              event.stopPropagation()
            }

            form.classList.add('was-validated')
          }, false)
        })
    })()
  }

  const closeButton = (event) => {
    common.resetForm({ className: "user-login" });
  };

  const passwordViewhandler = () => {
    if (passwordRef.current.type === "password") {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }

  }

  return (
    <>
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Login/ SignUp</h5>
              <button ref={ref} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeButton}></button>
            </div>
            <div className="modal-body loginmodal">
              <div className="row">
                <div className="col-6">
                  <form onSubmit={handlerLogin} className="needs-validation user-login">
                    <div className="mb-3">
                      {/* <label htmlFor="recipient-name" className="col-form-label">Login:</label> */}
                      <input type="email" className="form-control" id="email" placeholder="Email" required />
                      <div className="invalid-feedback">
                        Email or Password is wrong.
                      </div>
                    </div>
                    <div className="mb-3">
                      {/* <label htmlFor="password" className="col-form-label">Password:</label> */}
                      <div className="input-group">
                        <input ref={passwordRef} type="password" className="form-control" id="password" placeholder="Password" minLength="8" required />
                        <span class="input-group-btn">
                          <button class="btn btn-default reveal border border-start-0 rounded-start-0 " type="button" onClick={passwordViewhandler}><i class="bi bi-eye"></i></button>
                        </span>
                      </div>

                      <div className="invalid-feedback">
                        Password is not entered or its not valid.
                      </div>
                      <Link to={'/forgetpassword'}>
                        <div className="headingforgetpassword" data-bs-dismiss="modal">Forgot Password?</div>
                      </Link>
                      <div className="col mt-4" style={{ alignSelf: "start" }}>
                        {loading === false ? <button className="main-btn"  >Login</button>
                          : <button type="button" className="main-btn ms-4 mb-3 mt-4" disabled>
                            <div className="spinner-border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </button>}
                      </div>
                    </div>

                  </form>
                </div>
                <div className="vertical-line" style={{ width: "1px" }}></div>
                <div className="col-6 text-center" style={{ alignSelf: "center" }}>

                  {isDesktop && <p className="mb-3">Not a member? Register now to find rooms and contact other members</p>}
                  {isMobile && <p className="mb-3 ">Not a member? <br /> Sign Up </p>}
                  <button type="button" className="main-btn ms-4 mb-3 mt-4" onClick={handlerRegisterNav}>Sign Up</button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


export default Loginregistrationmodal