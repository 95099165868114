let hostUrl;

if(process.env.NODE_ENV === "production")
{
    hostUrl = process.env.REACT_APP_BACKEND_URL
}
else if (process.env.NODE_ENV === "development")
{
    hostUrl = process.env.REACT_APP_BACKEND_URL
}
else {
    // Default pick from the env file
    hostUrl = process.env.REACT_APP_BACKEND_URL
}

const config = {
     headers:{"Content-Type": "application/json",
              "Accept": "application/json",
           
     },
     host: hostUrl,
} 

export default config;
