import { useEffect, useState } from "react";
import Loginregistrationmodal from "./LoginRegisterModal";
import './css/header.css';
import {useNavigate} from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const Header = ({isMembershipEnabled}) => {
  const navigate = useNavigate();
  const [loggedinData, setLoggedinData] =  useState({});
  const user = useSelector((state) => state.user);

  useEffect(()=> {
    if (localStorage.getItem('Authorization')){
      const userKeys = ["name", "gender", "email", "profileImg", "id", "userType" ];
      const userData = {};
      userKeys.map((key)=> {
        if (localStorage?.[key]) userData[key] = localStorage[key];
      
      })
      
      if (userData != {}){
        setLoggedinData(userData);
      }
    }
    
  }, [])
  const loginDataHandler = (respData)=> {
    
    setLoggedinData(respData);
  };
  
  const [scrollOn, setScrollOn] = useState('');

  useEffect(() => {
    const handleScroll = () => {
      // Update state based on scroll position
      if (window.scrollY > 0) {
        setScrollOn('scroll-on');
      } else {
        setScrollOn('');
      }
    };

    // Add event listener for scroll
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts or when useEffect re-runs.
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    localStorage.clear();
    setLoggedinData({});
    navigate('/');
    navigate(0);
  };
    
  return (
    <>
      <nav className={`navbar fixed-top navbar-expand-lg navigation-wrap ${scrollOn}`}>
      <div className="container">
        <a className="navbar-brand px-0" href="/"><img decoding="async" src= "/images/logo3blue.png" alt="description" style={{height:"3rem", marginTop: "-8px"}}/></a>
       
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText"
          aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fas fa-stream navbar-toggler-icon" ></i>
          
          
        </button>
        <div className="collapse nav-items-headers navbar-collapse m-lg-auto float-lg-end " id="navbarText">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          {loggedinData?.email || user?.email ?<>
            { 
              isMembershipEnabled ? (localStorage.getItem('membershipStatus') === 'true' ? ''
              : <li className="nav-item">
              <a className="nav-link mt-3" href="/membership">Become A Member</a>
              </li>):''
            }
           {/* <li className="nav-item">
              <a className="nav-link mt-4" href="/add-listing">Add Listing</a>
            </li> */}

            <li className="nav-item">
              <a className="nav-link mt-3" href="/adds/">My Advertisement</a>
            </li>
            <li className="nav-item">
              <a className="nav-link mt-3" href="/message">Messages</a>

            </li>

            <li className="nav-item dropdown mt-3">
              
              <a className="nav-link dropdown-toggle" href="#/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="row">
                    <div className="col">
                      {(loggedinData?.name || user?.name|| 'profile').split(' ')[0]}
                       &nbsp;&nbsp;
                    </div>
                    {/* <div className="col">
                    <img className="img-thumbnail rounded-circle" style={{"width": "50px",  "height": "50px"}} src="https://mdbootstrap.com/img/Photos/Avatars/img%20(30).jpg"/>
                    </div> */}
                  </div>            
                  </a>
              
              
             
              {/* <img className="rounded-circle " alt="avatar1" src="https://mdbcdn.b-cdn.net/img/new/avatars/9.webp" style={{"width": "10px"}}/> */}
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown" >
                <Link to={'/editprofile/'}>    
                <li><a className="dropdown-item" href="/">Edit Profile</a></li>
                </Link>
                {/* <li><a className="dropdown-item" href="#">Another action</a></li> */}
                <li><hr className="dropdown-divider"/></li>
                <li><button type="button" className="main-btn" onClick={handleLogout}> Logout </button>
                </li>
              </ul>
            </li>
           </> :
           <>
           <li className="nav-item">
              <a className="nav-link pt-3" href="/register">{isMembershipEnabled ? "Add Listing" : "Free Add Listing"}</a>
            </li>

            <li className="nav-link">
              <button type="button" className="main-btn" data-bs-toggle="modal" data-bs-target="#exampleModal" data-bs-whatever="@mdo"> Login / SignUp <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAKCAYAAADcpvzeAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABxSURBVHgB7ZTBDYAgDEV/xYEcwREcQcMA7kMCOIIb1vZqMIED9cK7kLxLX2hSoDMxxr3FT+gMEa0yPNd6E1JKV2n4lzehNopCCItzboMBsqaTmW/v/YFXlHhWP8MYiaKCY31gSRb0J2r9iPklRmk9jA/qV1DhFaanqwAAAABJRU5ErkJggg=="></img></button>

            </li>
            
            </>
          }
          </ul>
        </div>
      </div>
    </nav>
    <Loginregistrationmodal  loginDataHandler={loginDataHandler}/>
    </>
  );
};

export default Header;
