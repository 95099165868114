import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import React, { Suspense, lazy, useEffect, useState, } from "react";
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import store from "./store"
import "./index.css";
import { createBrowserRouter, RouterProvider, Outlet, ScrollRestoration } from "react-router-dom";
import Body from "./components/Body";
import Register from "./components/Register";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "bootstrap-icons/font/bootstrap-icons.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tenant from "./components/Tenant";
import Owner from "./components/Owner";
import Adminlogin from "./components/admin/Adminlogin";
import Columnnavbar from "./components/admin/Columnnavbar";
import Adminbody from "./components/admin/Adminbody";
import Rules from "./components/admin/Rule";
import AdContent from "./components/admin/AdContent";
import UserAdds from "./components/userAdds";
import MessageList from "./components/messageList";
import Contactus from "./components/Contactus";
import Membership from "./components/Membership";
import Features from "./components/admin/Features";
import PaymentStatus from "./components/paymentStatus";
import fetchFunction from "./utils/fetch";
import {useLocation} from "react-router-dom";

import Forgetpassword from "./components/Forgetpassword";
import Editprofile from "./components/Editprofile";
import Adminmembership from "./components/admin/Adminmembership";
import AdminOrder from "./components/admin/AdminOrder";
import ContentBySlug from "./components/ContentBySlug";
import PageNotFound from "./components/pageNotfound";
import GeoMap from "./components/geomap";
import AdminUserControls from "./components/admin/AdminUserControls";
import AdmincityBrowse from "./components/admin/AdmincityBrowse";
import Addlisting from "./components/Addlisting";
import Successmessage from "./components/Successmessage";
import Spinner from "./components/spinner";
import Adminflagads from "./components/admin/Adminflagads";
import apiMapper from "./utils/apiMapper";
import { message } from "antd";

const Ownerproductpage = lazy(() => import("./components/Ownerproductpage"));
const Tenantproductpage = lazy(() => import("./components/Tenantproductpage"));

const Applayout = ({isMembershipEnabled}) => {
  const location = useLocation();
  const productUrl = location.pathname;

  const ProductPage = ['tenantproduct', 'ownerproduct', 'message', 'adds', 'map']
  const isProductPage = ()=>{
    return ProductPage.filter((e)=>{
      if(productUrl.includes(e)) return true
    }).length > 0
  }

  return (
    <>
      <Header isMembershipEnabled={isMembershipEnabled}/>
      <Outlet/>
      {!isProductPage() && <Footer />}
      <ScrollRestoration />
    </>
  )
};

const Adminlayout = () => {
  return (
    <>
     
      <div className="container-fluid">
        <div className="row flex-nowrap">
        <div className="col-auto col-md-3 col-xl-2 px-sm-2 px-0 bg-dark">
          <Columnnavbar />
          </div>
          <div className="col py-3">
          <Outlet />
          </div>
        </div>
      </div>

    </>
  )
}

function App() {
  const [isMembershipEnabled, setIsMembershipEnabled] = useState(false); // State to enable/disable route
  const [isLoading, setIsLoading] = useState(true);
  const membershipFeature = 'membership'
  useEffect(() => {
    const fetchFeatureData = async () => {
      const response = await fetchFunction.get(apiMapper.GET_ALL_FEATURE)
      if ([200, 201].includes(response.status)) {
        const data = response?.data?.data
        const feature = data?.filter(item => { return item.name.toLocaleLowerCase() === membershipFeature && item.status !== 'inactive' })
        if (feature.length > 0) {
          setIsMembershipEnabled(true)
        }
      }
    }
    try{
      fetchFeatureData()
    }catch (error) {
      message.error('Unable to fetch feature');
    } finally {
      setIsLoading(false);
    }
  },[])
  const routes = [
    {
      path: "/",
      element: <Applayout isMembershipEnabled={isMembershipEnabled}/>,
      children: [
        {
          path: "/",
          element: <Body isMembershipEnabled={isMembershipEnabled}/>,
        },
        {
          path: "/map",
          element: <GeoMap/>,
        },
        {
          path: "/register",
          element: <Register isMembershipEnabled={isMembershipEnabled}/>,
        },
        {
          path: "/successmessage/listing",
          element: <Successmessage isMembershipEnabled={isMembershipEnabled}/>,
        },
        {
          path: "/add-listing",
          element: <Addlisting isMembershipEnabled={isMembershipEnabled}/>,
        },
        {
          path: "/find-tenants/:address",
          element: <Tenant isMembershipEnabled={isMembershipEnabled}/>
        },
        {
          path: "/find-rooms/:address",
          element: <Owner isMembershipEnabled={isMembershipEnabled}/>
        },
        {
          path: "/ownerproduct/:ownerId",
          element:
            <>
              <Suspense fallback={<Spinner/>}>
                <Ownerproductpage isMembershipEnabled={isMembershipEnabled}/>
              </Suspense>
            </>
        },
        {
          path: "/tenantproduct/:tenantId",
          element:
            <>
              <Suspense fallback={<Spinner/>}>
                <Tenantproductpage isMembershipEnabled={isMembershipEnabled}/>
              </Suspense>
            </>
        },
        {
          path: "/adds/",
          element:
            <>
              <Suspense fallback={<Spinner/>}>
              <UserAdds/>
              </Suspense>
            </>
        },
        {
          path: "/message/",
          element: <MessageList/>
          
             
        },
        {
          path: "/contactus/",
          element: <Contactus/>
          
             
        },
        ...(isMembershipEnabled
          ? [
              {
                path: "/membership",
                element: <Membership />
              }
          ]: []
        ),
        {
          path: "/forgetpassword/",
          element: <Forgetpassword/>
          
             
        },
        {
          path: "/editprofile/",
          element: <Editprofile/>
          
             
        },
        ...(isMembershipEnabled
          ? [
            {
              path: "/paymentStatus/",
              element: <PaymentStatus />
            }
          ] : []
        ),
  
        {
          path: "/content/:slug",
          element: <ContentBySlug/>        
        },
  
      ],
    },
  
    {
      path: "/admin",
      element: <Adminlayout />,
      children: [
        {
          path: "/admin",
          element: <Adminbody />,
  
        },
        {
          path: "/admin/login",
          element: <Adminlogin />,
  
        },
        {
          path: "/admin/rules",
          element: <Rules />,
  
        },
        {
          path: "/admin/content",
          element: <AdContent />,
  
        },
        {
          path: "/admin/membership",
          element: <Adminmembership />,
  
        },
        {
          path: "/admin/order",
          element: <AdminOrder />,
  
        },
        {
          path: "/admin/usercontrol",
          element: <AdminUserControls />,
  
        },
          {
          path: "/admin/citybrowse",
          element: <AdmincityBrowse />,
  
        },
          {
          path: "/admin/flag-ads",
          element: <Adminflagads />,
  
        },
        {
          path: "/admin/features",
          element: <Features />,
  
        },
        
      ],
    },
    {path: "*",
    element: <PageNotFound/>
  }
  ]
  const appRouter = createBrowserRouter(routes);
  if(isLoading){
    return <Spinner/>
  }
  return (
          <RouterProvider router={appRouter} />
  );
}


render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
