import "./userviewcrousal.css";
import Slider from "react-slick";
import "./css/userView.css";
import { useEffect, useState } from "react";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, Pagination } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const Userview = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    // nextArrow: <GalleryNextArrow />,
    // prevArrow: <GalleryPrevArrow />,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  
  const [userData, setUserData] = useState([]);
  //  change in deployment
  async function fetchAdds() {
    const newAddsObj = await fetchFunction.get(apiMapper.GET_NEW_ADDS);
    if (newAddsObj?.status === 200) {
      const data = newAddsObj?.data?.data || [];
      const addsList = [];
      data.map((e) => {
        addsList.push({
          name:
            e?.userId?.name.length > 15
              ? e?.userId?.name.slice(0, 15) + ".."
              : e?.userId?.name,
          roomTypes: e?.roomTypes ,
          userText:
            (e.description || "").length > 15
              ? (e.description || "").slice(0, 15) + ".."
              : e.description || "",
              image:
              e?.photos[0] || (
                `/images/placeholder/${e?.userType === "owner" ? 'owner' : 
                  (e?.userId?.gender === "male" ? 'tenantM' : 'tenantF')
                }.jpg`
              ),
          _id: e._id
        });
      });

      setUserData(addsList);
    }
  }
  // async function fetchAdds() {
  //   const newAddsObj = await fetchFunction.get(apiMapper.GET_NEW_ADDS);
  //   if (newAddsObj?.status === 200) {
  //     const data = newAddsObj?.data?.data || [];
  //     const addsList = [];
  //     data.map((e) => {
  //       const image =
  //               e?.photos && e?.photos.length > 0
  //                   ? `http://localhost:6167/${e.photos[0]}`
  //                   : `/images/placeholder/${e?.userId?.gender || "male"}.jpg`;
  //       addsList.push({
  //         name:
  //           e?.userId?.name.length > 15
  //             ? e?.userId?.name.slice(0, 15) + ".."
  //             : e?.userId?.name,
  //         roomTypes: e?.roomTypes ,
  //         userText:
  //           (e.description || "").length > 15
  //             ? (e.description || "").slice(0, 15) + ".."
  //             : e.description || "",
  //             image,
  //             _id: e._id,
  //       });
  //     });

  //     setUserData(addsList);
  //   }
  // }
  useEffect(() => {
    fetchAdds();
  }, []);
  // console.log("userData",userData);
  const slidesPerViewConfig = isDesktop ? 5 : isTablet ? 3 : 2; 
  const userContent = () => {
    return userData.map((ele) => {
      return  <SwiperSlide
        style={{overflow:"auto"}}
      >
        <div><a href={'/ownerproduct/'  + ele._id}>
                <div className="card" style={{ width: "95%", height:"110%" }}>
                  <img
                    src={ele.image}
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">{ele.name}</h5>
                    <h5 className="text-size"> 
                      {[
                        ele?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? '3 Sharing' :
                        ele?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? '2 Sharing' :
                        ele?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private room' :
                        ele?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' :
                        ''
                      ]}<span></span>
                    </h5>
                    <p className="card-text">{ele.userText}</p>
                  </div>
                </div>
              </a>
            </div>
      </SwiperSlide> 

    });
  };

  return (
    <>
      <div className="container  mt-5 mb-5 user-corosoul">
        <div className="row text-center">
          <div className="col-3"></div>
          <div className="col-6">
            <h3>EkSarathi</h3>
            <p>EkSarathi's for you </p>
          </div>
          <div className="col-3"></div>
        </div>
    <Swiper
      navigation={true}
      pagination={{ clickable: true }}
      modules={[Autoplay, Pagination, Navigation]}
      spaceBetween={10}
      slidesPerView={slidesPerViewConfig}
      autoplay={{
        delay: 7000,
        disableOnInteraction: false,
      }}
      // speed={5000}
      // loop={true}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {userContent()}
    </Swiper>
      </div>
    </>
  );
};

export default Userview;
