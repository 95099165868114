import Commonlisting from "./Commanlisting"


const Owner = ({isMembershipEnabled}) => {
  return (
    <>
    <Commonlisting  ownerType={'owner'} isMembershipEnabled={isMembershipEnabled}/>
    </>
  )
}

export default Owner