import { useEffect, } from "react";
import fetchFunction from "../utils/fetch";
import apiMapper from "../utils/apiMapper";
import { useState } from "react";
import Contactme from "./Contactme";
import Shimmeruitenant from "./Shimmeruitenant";
import "../components/css/commonlisting.css";
import Filter from "./Filter";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Buffer } from 'buffer';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { SkeletonCommonListing } from "./SkeletonCommonListing";

import _ from 'lodash';
import haversineDistanceMiles from "../utils/calculateDistance";
import DistanceSearch from "./DistanceSearch";


const Commonlisting = ({ ownerType, tenantType, isMembershipEnabled }) => {

  const isDesktop = useMediaQuery({ minWidth: 768 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const pageType = (ownerType);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(1);
  const [limit] = useState(6);  
  // const { city } = useParams();
  const [Distance, setDistance] = useState(100)

  const queryParams = new URLSearchParams(window.location.search)
  const longitude = queryParams.get('longitude')
  const latitude = queryParams.get('latitude')
  const address = queryParams.get("address")
  const [isLoading, setLoading] = useState(true)
  

  const [combinedFilter, setCombinedFilter] = useState({
    page :1,
    sort: 'relevance',
    filters: {},
    membershipStatus: 'false'
  });

  async function getAllRecords() {
    setLoading(true)
    let filterEncoded = ""
    let url = `?page=${combinedFilter.page - 1}&limit=${limit}&sort=${combinedFilter.sort}&membershipStatus=${combinedFilter.membershipStatus}&distance=${Distance}&longitude=${longitude}&latitude=${latitude}`
    if (Object.keys(combinedFilter.filters).length) {

      filterEncoded = Buffer.from(JSON.stringify(combinedFilter.filters)).toString('base64');

      url = url + `&filters=${filterEncoded}`;
    }

    let mapperUrl = (pageType === 'owner') ? (apiMapper.GET_ALL_Owner) : (apiMapper.GET_ALL_Tenant);
    const response = await fetchFunction.get(mapperUrl + url);
    if(response?.status === 200){
      setLoading(false)
    }
    const finalresponse = response?.data?.data || []
    const count = response?.data?.totalCount;
    setTotalCount(count);
    setPageData(finalresponse);
    
  }

  //console.log("pageData", pageData);

  useEffect(() => {
    getAllRecords()

  }, [combinedFilter, Distance]);
// console.log("distance", Distance)
  const filterDataHandler = async (filterData) => {
    if (Object.keys(filterData).length && !_.isEqual(filterData, combinedFilter.filters)) {
      setCombinedFilter({...combinedFilter,filters: filterData , page: 1})
      
    }
  
  }
  function capitalizeFirstLetter(string) {
    // Check if the string is null, undefined, or empty after trimming
      if (!string || typeof string !== "string" || string.trim() === "") {
          return ""; // Return an empty string if the input is null, undefined, or empty
      } else {
          return string.charAt(0).toUpperCase() + string.slice(1);
      }
  }

  return (
    <>
    <div>
      <Helmet>
        {/* Meta tags for SEO */}
        <meta name="description" content="Find rooms and apartments for rent at Eksarathi. Explore our listings and find the ideal accommodation for your needs." />
        <meta name="keywords" content="room for rent, apartment for rent, affordable housing, Eksarathi, Indian Roommates, Indian Rentals, Desi rentals, South Indian Rentals, North Indian Rentals, Short term rentals, Long Term Rentals, Furnished room, Unfurnished room, Desi Owners, Desi Tenants, Desi Roommates, Student Rentals, Indian Student Rentals, Telugu Roommates, Tamil Roommates, Nepali Rentals, Pakistani Rentals, Bangladeshi Rentals, roomshare, house share, sublets, apartment share" />
        <meta name="author" content="Eksarathi" />

        {/* Open Graph meta tags for social media sharing */}
        <meta property="og:title" content="Eksarathi - Search Affordable Rooms and Apartments for Rent in the USA" />
        <meta property="og:description" content="Explore our listings and find the ideal accommodation for your needs at Eksarathi." />
        <meta property="og:image" content="url-to-your-image.jpg" />
        <meta property="og:url" content="https://www.eksarathi.com/findrooms" />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Eksarathi - Search Affordable Rooms and Apartments for Rent in the USA" />
        <meta name="twitter:description" content="Explore our listings and find the ideal accommodation for your needs at Eksarathi." />
        <meta name="twitter:image" content="url-to-your-image.jpg" />

        {/* Page title */}
        <title>Find Your Perfect Rental: Search Rooms & Apartments in the USA | Eksarathi</title>
      </Helmet>
      {/* Your component content */}
    </div>
      <div className="container-fluid min-vh-100 commonlisting">
        <div className="container commonlistingContainer">
          {/* <div className="row headingTitle pt-5 pt-md-0">
            <div className="headingcontent">{ownerType === 'owner' ? (address ?  `Room Result for ${(address)}`: 'Room Results') :
             `Tenant Results for ${address}`} </div>
          </div> */}
          <div className="row pt-3">

            {/* <!-Mobile - Modal --> */}
            <div class="modal fade" id="findmoreModal" tabindex="-1" aria-labelledby="findmoreModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <div className=" row rounded-3" style={{ backgroundColor: "white" }}>
                      <section>
                        <div className="row filter1">
                          <h3>Filter</h3>
                        </div>
                      </section>
                      <Filter pushfilterData={filterDataHandler} userType={pageType} />
                      <DistanceSearch setRange={setDistance} IsMobile={isMobile}/>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                  </div>
                </div>
              </div>
            </div>
            {/* ----Filter---- */}
            {isMobile && (<>
              <div className="container filter">
                <div className="row shadow-sm adjustment" style={{ backgroundColor: "white" }}>
                  {isMembershipEnabled ? <div className="col-5 py-3">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="membership" 
                       onClick={()=>{setCombinedFilter({...combinedFilter, membershipStatus: 
                       combinedFilter.membershipStatus === "true" ? "false": "true"})}} />
                      <label class="form-check-label" for="membership">
                        <img src="/images/placeholder/premium.png" height={20} width={50} alt='premium-member' />
                      </label>
                    </div>
                  </div>:''}
                  <div className=" col-2">
                    <div className="verticalLine"></div>   
                  </div>

                  <div className="col-5 ">
                    <b>Filter</b> <button className="btn btn-light m-2" data-bs-toggle="modal" data-bs-target="#findmoreModal" >
                      <i class="bi bi-list" style={{ fontSize: "22px" }}></i></button>
                  </div>
                </div>
              </div>
            </>)}

            {isDesktop && <div className="col-md-4 rounded-3" style={{ backgroundColor: "white" }}>

              <div className="row filter1">
                <h3>Filter</h3>
                {isMembershipEnabled ?<div className="row">
                  <div class="px-5 form-check">
                    <input class="form-check-input" type="checkbox" value="" id="membership" onClick={()=>
                      {setCombinedFilter({...combinedFilter, membershipStatus: combinedFilter.membershipStatus === "true" ? "false": "true"})}} />
                    <img src="/images/placeholder/premium.png" height={35} width={100} alt='premium-member' />
                  </div>
                </div>:''}
              </div>

              <Filter pushfilterData={filterDataHandler} userType={pageType} />
              <DistanceSearch setRange={setDistance}/>
            </div>}
            {/* -----Main---- */}
            <div className="col-12 col-md-8 main">
              <div className="container">
                <div className="row my-1" style={{ backgroundColor: "white" }}>
                  <div>{`${pageData.length === 0 ? 0:(combinedFilter.page-1) * limit + 1}-${(combinedFilter.page-1) * limit + pageData?.length} of over ${totalCount} ${pageType === 'owner' ? ("Rooms"):("Tenants")} Results for `}<span className="span-address-bold">{address}</span> </div>
                  <div className="row sortinghead">
                    <ul>
                      {/* <li><strong>sort By</strong></li> */}
                      <li><a href='#/' className={combinedFilter.sort === "relevance" ? "sortingSubHeading":'' } onClick={()=>{setCombinedFilter({...combinedFilter, sort: "relevance"})}}>Relevance</a></li>
                      <li><a href='#/' className={combinedFilter.sort === "newest" ? "sortingSubHeading":'' }  onClick={()=>{setCombinedFilter({...combinedFilter, sort: "newest"})}}>Newest</a></li>
                      <li><a href='#/' className={combinedFilter.sort === "oldest" ? "sortingSubHeading":'' }  onClick={()=>{setCombinedFilter({...combinedFilter, sort: "oldest"})}}>Oldest</a></li>
                    </ul>
                  </div>
                </div>
                {isLoading ? <SkeletonCommonListing/>:(pageData?.length === 0 ? (<div style={{margin:'10% 0', textAlign:'center', fontWeight:'bold'}}>No results found</div>) : (<>
                  {pageData?.map((ele) => {
                    return (
                      <>
                        <div className="row mt-2 py-2 rounded-3 rounded-top-0 mb-2 border-2 shadow-sm " style={{ backgroundColor: "white" }}>
                          <div className="col-12 col-md-3 p-4 text-center">
                            {isMobile && <Link to={(pageType === ownerType) ? ("/ownerproduct/" + ele._id) : ("/tenantproduct/" + ele._id)}>
                              <img
                                 src={ele?.photos[0] || 
                                 `/images/placeholder/${ele?.userType === "owner" ? 'owner' : 
                                 (ele?.userId?.gender === "male" ? 'tenantM' : 'tenantF')}.jpg`
                                 }
                               
                                alt="user image"
                                className="imagebg" />
                            </Link>}
                            {/* change in deployment */}
                            {isDesktop && <Link to={(pageType === ownerType) ? ("/ownerproduct/" + ele._id) : ("/tenantproduct/" + ele._id)}>
                              <img
                                 src={ele?.photos[0] || 
                                 `/images/placeholder/${ele?.userType === "owner" ? 'owner' : 
                                 (ele?.userId?.gender === "male" ? 'tenantM' : 'tenantF')}.jpg`
                                 }
                               
                                alt="user image"
                                className="imagebg" />
                            </Link>}
                            {/* {isDesktop && <Link to={(pageType === ownerType) ? ("/ownerproduct/" + ele._id) : ("/tenantproduct/" + ele._id)}>
                              <img
                              // change in deployment
                                 src={
                                  (!ele.photos[0] || ele.photos[0] === "")
                                    ? `/images/placeholder/male.jpeg`
                                    : ele.photos[0]
                                }
                                // src={
                                //   ele?.photos && ele?.photos.length > 0
                                //   ? `http://localhost:6167/${ele.photos[0]}`
                                //   : `/images/placeholder/${ele?.userId?.gender || "male"}.jpg`
                                // }
                               
                                alt="user image"
                                className="imagebg" />
                            </Link>} */}
                          </div>
                          <div className=" col-12 col-md-6">
                            <div className="row maincontent">
                              <Link to={(pageType === ownerType) ? ("/ownerproduct/" + ele._id) : ("/tenantproduct/" + ele._id)}>
                                <a href={null}>{(pageType === ownerType) ? (`${(ele?.roomTypes === 'room-shared-2' ? '3 Sharing' : '') || (ele?.roomTypes === 'room-shared-1' ? '2 Sharing' : '') || (ele?.roomTypes === 'private-room' ? 'Private' : '')} Room in ${ele?.location?.address}`) : `Looking for room in ${ele?.location?.address}`}</a>
                              </Link>
                            </div>
                            <div className="row px-2 liststyle">
                              <ul className="listingdisplay my-2 px-4">
                                <li><span className="subheading">Room Type</span>: {[
                                  ele?.roomTypes.some(r => r.roomTypes === 'room-shared-2') ? '3 Sharing' : '',
                                  ele?.roomTypes.some(r => r.roomTypes === 'room-shared-1') ? '2 Sharing' : '',
                                  ele?.roomTypes.some(r => r.roomTypes === 'private-room') ? 'Private room' : '',
                                  ele?.roomTypes.some(r => r.roomTypes === 'full-house') ? 'Full House' : ''
                                ].filter(Boolean).join(', ')}</li>
                                <li><span className="subheading">Distance</span>: {haversineDistanceMiles(latitude, longitude,ele?.geoLocation.coordinates[1],ele?.geoLocation.coordinates[0])} Miles Approx</li>
                                {/* <li><span className="subheading">Location</span>: {capitalizeFirstLetter(ele?.city.name)}</li> */}
                                <li><span className="subheading">Name</span>: {ele?.userId?.name}</li>
                                <li><span className="subheading">{(ele?.userType === 'owner' ?'Available From' : 'Need From')}</span>: {moment(ele?.availableFrom).format('ll') || '--'}</li>
                                <li><span className="subheading">Rent</span>: ${ele?.rent  || '--'}</li>
                                {/* <li><span className="subheading">Offer For</span>: {ele?.offerFor}</li> */}
                                <li>
                                  <span className="subheading">Furnish Type</span>: {
                                    (ele?.furnishingTypes && ele.furnishingTypes.length > 0 ? 
                                      capitalizeFirstLetter(
                                        ele.furnishingTypes
                                          .map((furnishType) => furnishType?.furnishingType)
                                          .join("  ")
                                          .replace(/-/g, ' ') 
                                      )
                                      : 
                                      "N/A"
                                    )
                                  }
                                </li>
                                  <li><span className="subheading">User Type</span>: {capitalizeFirstLetter(ele?.userType)}</li>
                                  {/* <li><span className="subheading">User Type</span>: {ele?.userType}</li> */}
                              </ul>
                            </div>
                          </div>
                          <div className="col-12 col-md-3 mt-2 mb-3 mb-md-0 findmore">
                            <div className="row">
                              <div className="col-6 col-md-12 my-2 my-md-0">
                                <Link to={(pageType === ownerType) ? ("/ownerproduct/" + ele._id) : ("/tenantproduct/" + ele._id)}>
                                  <button
                                    type="button"
                                    className="main-btn"
                                  >
                                    Find more...
                                  </button>
                                </Link>
                              </div>
                              <div className="col-6 col-md-12 text-start py-3 mt-md-5 py-md-3 mx-md-3">
                                {ele?.userId?.membershipStatus && isMembershipEnabled ? 
                                  <img src="/images/placeholder/premium.png" height={40} width={100} alt='premium-member' />:''}
                              </div>
                            </div>

                          </div>
                        </div>
                      </>
                    );
                  })}

                </>))}
              </div>

              {/* --------  pagination------- */}
                <div className="row mb-2">
                <nav aria-label="Page navigation example">
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <a className={"page-link " + (combinedFilter.page <= 1 ? 'disabled' : '')} href={null} aria-label="Previous" onClick={()=>{setCombinedFilter({...combinedFilter ,page: combinedFilter.page - 1 })}}>
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>

                      </a>
                    </li>
                    {totalCount > 0 && [...Array(Math.ceil(totalCount/limit) ).keys()].slice(0,3).filter((pgNumber, i) => {
                    
                      if(pgNumber + combinedFilter.page < Math.ceil(totalCount/limit) +1){
                        return true;
                      }
                      

                    }).map((pgNumber)=>{
                      return <li key={pgNumber + combinedFilter.page }
                          className={`page-item ${combinedFilter.page === pgNumber + combinedFilter.page  ? 'active' : ''} `} >

                          <a onClick={() => setCombinedFilter({...combinedFilter ,page:pgNumber + combinedFilter.page })}
                            className='page-link'
                            href={null}>

                            {pgNumber + combinedFilter.page}
                          </a>
                        </li>
                    })}

                    <li className="page-item">
                      <a className={"page-link " + (pageData?.length < limit ? 'disabled' : '')} href={null} aria-label="Next" onClick={()=>{setCombinedFilter({...combinedFilter ,page: combinedFilter.page + 1 })}} >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Commonlisting;





