import { Link } from "react-router-dom";
import React from 'react';
import { Helmet } from 'react-helmet';


const Footer = () => {

  const footerMenu = [{ "name": "Home", "link": "#/", }, { "name": "About Us", "link": "#/","slug":"about" },
  { "name": "FAQ", "link": "#/","slug":"faq"  },
  { "name": "Preview", "link": "#/","slug":"preview"}, { "name": "Terms & Conditions", "link": "#/","slug":"termCondition"  },
  { "name": "Privacy Policy", "link": "#/", "slug": "privacy-policy" }, { "name": "Refund & Cancellation Policy", "link": "#/","slug":"refundPolicy"}
    , { "name": "Advertise With Us", "link": "#/","slug":"add"  },{ "name": "Contact Us", "link": "/contactus","slug":""  }]


    const aboutUsSEO = {
      title: "About Eksarathi - Affordable Rooms and Apartments for Rent in the USA",
      description: "Eksarathi invites you to an immersive community experience, a gateway designed to connect and support individuals on their unique journeys...",
      image: "url-to-your-image.jpg",
      url: "https://www.eksarathi.com/about",
    };
  
    const generateSEO = (page) => {
      if (page === 'about') {
        return aboutUsSEO;
      }

      return {
        title: "Eksarathi - Affordable Rooms and Apartments for Rent in the USA",
        description: "Find affordable rooms and apartments for rent at Eksarathi. Explore our listings and book your ideal accommodation.",
        image: "url-to-your-image.jpg",
        url: "https://www.eksarathi.com/",
      };
    };

    
    const advertiseSEO = {
      title: "Advertise With Eksarathi - Reach a Wide Audience for Room and Apartment Rentals",
      description: "Advertise with Eksarathi for rooms, apartments, flats, and tenants for rent. Reach a wide audience and explore our listings for your ideal rental space.",
      image: "url-to-your-image.jpg",
      url: "https://www.eksarathi.com/advertise",
    };
  
    const generateSEOAdv = (page) => {
      if (page === 'advertise') {
        return advertiseSEO;
      }
  
      // Default SEO for other pages
      return {
        title: "Eksarathi - Affordable Rooms and Apartments for Rent in the USA",
        description: "Find affordable rooms and apartments for rent at Eksarathi. Explore our listings and book your ideal accommodation.",
        image: "url-to-your-image.jpg",
        url: "https://www.eksarathi.com/",
      };
    };

  const footerMenuJsx = footerMenu.map((ele, i) => {
    return (<>
      <Link to = {ele.name ==="Home"? '/':(ele?.slug === '' ? ele?.link:  `/content/${ele?.slug}`)}>
        <li className="nav-item" key={i}><a href={ele.link} className="nav-link px-2 " key="ele.name" >{ele.name}</a></li>
      </Link>
    </>
    )
  })

  const currentPath = window.location.pathname.split('/').pop(); // Extract the last part of the path

    const currentSEO = generateSEO(currentPath);

    const currentPathAdv = window.location.pathname.split('/').pop(); // Extract the last part of the path

    const currentSEOAdv = generateSEO(currentPathAdv);
  
  return (
    <>
      <Helmet>
        {/* Meta tags for SEO */}
        <meta name="description" content={currentSEO.description} />
        <meta property="og:title" content={currentSEO.title} />
        <meta property="og:description" content={currentSEO.description} />
        <meta property="og:image" content={currentSEO.image} />
        <meta property="og:url" content={currentSEO.url} />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentSEO.title} />
        <meta name="twitter:description" content={currentSEO.description} />
        <meta name="twitter:image" content={currentSEO.image} />

        {/* Page title */}
        <title>{currentSEO.title}</title>
      </Helmet>

      <Helmet>
        {/* Meta tags for SEO */}
        <meta name="description" content={currentSEOAdv.description} />
        <meta property="og:title" content={currentSEOAdv.title} />
        <meta property="og:description" content={currentSEOAdv.description} />
        <meta property="og:image" content={currentSEOAdv.image} />
        <meta property="og:url" content={currentSEOAdv.url} />
        <meta property="og:type" content="website" />

        {/* Twitter Card meta tags for Twitter sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={currentSEOAdv.title} />
        <meta name="twitter:description" content={currentSEOAdv.description} />
        <meta name="twitter:image" content={currentSEOAdv.image} />

        {/* Page title */}
        <title>{currentSEOAdv.title}</title>
      </Helmet>
      <footer className="footer ">
        <div className="footer py-5">
          <div className="container">
            <div className="row">
              <ul className="nav justify-content-center  pb-3 mb-3">
                {footerMenuJsx}
              </ul>

              <div className="col-sm-12" >

                <div className="footer-social pt-4 text-center">
                  <a href="https://www.facebook.com/people/EkSarathi/61551116189275/?mibextid=LQQJ4d">
                    <i className="bi bi-facebook"></i>
                  </a>
                  <a href="https://twitter.com/eksarathi?s=11&t=LJ9r9vkghCdThorz60BVQQ">
                    {/* <img src="/images/img/icons/x.svg" alt="/images/img/icons/x.svg" /> */}
                    <i class="bi bi-twitter-x"></i>
                  </a>
                  <a href="https://www.linkedin.com/in/eksarathi-llc-b177b02a5/">
                    <i className="bi bi-linkedin"></i>
                  </a>
                  <a href="https://www.instagram.com/eksarathi/?igsh=OGQ5ZDc2ODk2ZA%3D%3D">
                    <i className="bi bi-instagram"></i>
                  </a>


                </div>
              </div>
              <div className="col-sm-12">
                <div className="footer-copy">
                  <div className="copy-right text-center pt-5">
                  <p className="">
                    © 2024. <a href="https://www.eksarathi.com">EkSarathi</a>
                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
