import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import "../components/css/distanceSearch.css";

const DistanceSearch = ({ setRange, IsMobile }) => {
  const [distance, setDistance] = useState(100);


  const debouncedRangeChange = useCallback(
    debounce((value) => {
      if (setRange) {
        setRange(value);
      }
    }, 300), // 300ms debounce delay
    []
  );
  
  useEffect(() => {
    debouncedRangeChange(distance);
  }, [distance, debouncedRangeChange]);

  const handleRangeChange = (e) => {
    setDistance(e.target.value);
  };

  return (
    <>
    <div className='distanceSearch'>
      <input
        type="range"
        min="0"
        max="200"
        value={distance}
        onChange={handleRangeChange}
        className='distanceSearchInput'
      />
      <div>Selected Range: {distance} Miles</div>
    </div>
    <div className={IsMobile ? 'seperationBorderMobile' : 'seperationBorder'}></div>
    </>
  );
};

export default DistanceSearch;
